
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/cards/Card.vue";
import KTInviteFriendsModal from "@/components/modals/general/InviteFriendsModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "invite-friends",
  components: {
    KTModalCard,
    KTInviteFriendsModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Invite Friends", ["Modals", "General"]);
    });
  },
});
